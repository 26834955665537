import { Keyring } from '@polkadot/api'
import { useMemo } from 'react'
import { useAccounts } from '../polkadotExtension/useAccounts'
import { useApi, useChainId } from '../useApi'
import { contractAddressByChain, getContractAddress } from '@/constants/contractAddressByChain'
import Router from '@/utils/types/contracts/router_contract'
import { sentryLogError } from '@/utils/env/sentry'

export const useRouterContract = () => {
  const api = useApi()
  const chainId = useChainId()
  const { account, signer, keyringPair } = useAccounts()
  const address = getContractAddress(
    contractAddressByChain.routerContract,
    chainId
  )

  return useMemo(() => {
    if (!address || !api) return null
    const keyring = new Keyring({ type: 'sr25519' })
    const aliceKeyringPair = keyring.addFromUri('//Alice')
    try {
      return new Router(
        address,
        keyringPair ?? aliceKeyringPair,
        api
      )
    } catch (error) {
      sentryLogError(error)
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, api, keyringPair])
}
