import {
  Center,
  CircularProgress,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { TransactionState } from '@/constants/transaction'

interface Prop {
  txState?: TransactionState
  setTxState: (state?: TransactionState) => void
  blockHash?: string
}

export const WaitForConfirmationModal: React.FC<Prop> = ({
  txState,
  setTxState,
  blockHash
}) => {
  return (
    <Modal
      isOpen={txState === TransactionState.WAITING_FOR_CONFIRMATION}
      onClose={() => {
        setTxState()
      }}
    >
      <ModalOverlay />
      <ModalContent p={10} mx={{ base: 3, lg: 'inherit' }}>
        <ModalCloseButton />
        <ModalBody fontSize={{ base: 14, lg: 16 }}>
          <Center mt={10} flexDir="column" textAlign="center" gap="1rem">
            <CircularProgress
              size="100px"
              isIndeterminate
              color="green.300"
              mb={5}
            />
            <Heading as="h2" size={{ base: 'sm', lg: 'md' }}>
              Waiting For Confirmation
            </Heading>
            <Text color="whiteAlpha.700">
              Confirm this transaction with your wallet
            </Text>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
