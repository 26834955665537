import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { TransactionState } from '@/constants/transaction'
import { SubscanLink } from './SubscanLink'

interface Prop {
  txState?: TransactionState
  setTxState: (state?: TransactionState) => void
  blockHash?: string
}

export const TxSuccessModal: React.FC<Prop> = ({
  txState,
  setTxState,
  blockHash
}) => {
  return (
    <>
      <Modal
        isOpen={txState === TransactionState.SUCCESS}
        onClose={() => {
          setTxState()
        }}
      >
        <ModalOverlay />
        <ModalContent p={10}>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={5} m={5}>
              <Icon as={CheckCircleIcon} boxSize={20} />
              <Heading as="h2" size="md">
                Transaction Successful
              </Heading>
              {blockHash && (
                <Text color="whiteAlpha.700">
                  <SubscanLink blockHash={blockHash} />
                </Text>
              )}
              <Button
                colorScheme="whiteAlpha.600"
                variant="outline"
                mt={5}
                mr={3}
                onClick={() => {
                  setTxState()
                }}
              >
                Close
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
