import BigNumber from 'bignumber.js'
import Pair from '@/utils/types/contracts/pair_contract'

export const getReserves = async (
  pair: Pair,
  tokenA: string,
  tokenB: string
) => {
  const result = await pair.query.getReserves()
  if (!result?.value.ok) {
    return
  }
  const [token0, token1] = result?.value.ok
  const token0Address = (await pair.query.getToken0()).value.ok;

  if (tokenA == token0Address) {
    return {
      tokenA: BigNumber(token0.toString()),
      tokenB: BigNumber(token1.toString())
    }
  }

  return {
    tokenA: BigNumber(token1.toString()),
    tokenB: BigNumber(token0.toString())
  }
}

export const getReservesByAddress = async (pair: Pair) => {
  const result = await pair.query.getReserves()
  if (!result?.value.ok) {
    return
  }
  const [token0, token1] = result.value.ok
  const { value: { ok: token0Address } } = await pair.query.getToken0()
  const { value: { ok: token1Address } } = await pair.query.getToken1()

  return {
    [token0Address as string]: BigNumber(token0.toString()),
    [token1Address as string]: BigNumber(token1.toString())
  }
}
