import { useMemo } from 'react'
import { useAllTokens } from './useTokens'
import { Token } from '@/constants/tokens/types'

export const useWrappedNativeToken = (): Token | undefined => {
  const allTokens = useAllTokens()
  return useMemo(() => {
    if (allTokens) {
      return allTokens.find(token => token.isWrapped)
    }
  }, [allTokens])
}
