import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link } from '@chakra-ui/react'

export const SubscanLink = ({ blockHash }: { blockHash: string }) => {
  return (
    <Link href={`https://shibuya.subscan.io/block/${blockHash}`} isExternal>
      View on SubScan <ExternalLinkIcon mx="2px" />
    </Link>
  )
}
