import { useMemo } from 'react';
import { useWrappedNativeToken } from './useWrappedToken';
import { Token } from '@/constants/tokens/types';

export const useGetWrappedTokenIfNative = (token?: Token) => {
  const wrappedToken = useWrappedNativeToken()
  return useMemo(() => {
    if (token?.isNative) {
      return wrappedToken
    }
    return token
  }, [token, wrappedToken])
}
