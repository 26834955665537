import { Keyring } from '@polkadot/api'
import type { KeyringPair } from '@polkadot/keyring/types'
import { useMemo } from 'react'
import { useApi } from '../useApi'
import PSP22 from '@/utils/types/contracts/psp22_token'
import { sentryLogError } from '@/utils/env/sentry'

export const usePsp22Contract = (address: string | undefined | null, signer: KeyringPair | null | undefined) => {
  const api = useApi()

  return useMemo(() => {
    if (!address || !api) return null
    const keyring = new Keyring({ type: 'sr25519' })
    const aliceKeyringPair = keyring.addFromUri('//Alice')
    try {
      return new PSP22(
        address,
        signer ?? aliceKeyringPair,
        api
      )
    } catch (error) {
      sentryLogError(error)
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, api, signer])
}
