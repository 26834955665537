import { chainIdByName } from '@/constants/chainIdByName'

interface contractAddressByChainID {
  [key: number]: string
}

export const contractAddressByChain = {
  flipperExample: {
    [chainIdByName.shibuya]: 'bRcYQwu55YuJNcmfEHGQiqerDYPJVjgkpizurzMmsuyQyMs'
  },
  routerContract: {
    [chainIdByName.shibuya]: 'YW49aaxeDd9UqjGC9qXJ3kqYpLkJdKeQQJLPqr23apbLYoa',
    [chainIdByName.shiden]: 'bcQB3EqAezxQLRFBt2c2D5XHciDtdNBDgMg2vqxE4Dh82Mz',
  },
  factoryContract: {
    [chainIdByName.shibuya]: 'XYFKRHns5t2Y8xeyfFcVhqw4ax1Sh4ghGuKHqRz8jrjzo5F',
    [chainIdByName.shiden]: 'X77YeK53wbm7XyTJzMv78UeFYT63SYEZ32JFQz4sEyaUHXz',
  },
  masterChefContract: {
    [chainIdByName.shibuya]: 'bHxxuSeF8CoKyD5WnNk98dhneKZZthPmsE8Y2tJsq1EmdtS',
    [chainIdByName.shiden]: 'WDki8qkeQ8QKFMx7qpGT8akCnphH58wCbXaaPAkBeZUMC9t'
  }
}
export const getContractAddress = (contractAddressByChainID: contractAddressByChainID, chainId: number | null | undefined ): string | null => {
  if (!chainId) { return null }
  return contractAddressByChainID[chainId]
}
